import logo from './logo.svg';
import './App.css';

function App() {
  document.title = 'Hi !'
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Hi there !! <br/> Website comming soon
        </p>
        <a
          className="App-link"
          href="https://jksenterprise.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          JKS Enterprise
        </a>
      </header>
    </div>
  );
}

export default App;
